import {ReactNode} from 'react'
import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    height: 'calc(100% - 96px - 32px)',
  },

  contentContainer: {
    margin: theme.spacing(2),
    height: '100%',
    flexGrow: 1,
  },
}))

export function PageContainer(props: PageContainerProps) {
  const {children} = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>{children}</div>
    </div>
  )
}

type PageContainerProps = {
  children: ReactNode
}
