/* eslint-disable array-callback-return */
import {useState} from 'react'
import {Paper, Theme} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Route, Switch} from 'react-router-dom'

import {MainAppBar} from './main-app-bar'
import {TabNavigation} from './tab-navigation'
import {ReleasePlan, Ideas, Bookmarks, KnownIssues} from '../../pages'
import {ResponsiveDrawer} from './responsive-drawer'
import {PbiTipsToolsLinks} from './pbi-tips-tools-links'
import {PbiTipsSocialLinks} from './pbi-tips-tools-links'
import {Box} from '@mui/system'

const useStyles = makeStyles((theme: Theme) => ({
  headerGroup: {height: '96px'},
  tabs: {
    flexGrow: 1,
    height: '48px',
    width: '100%',
    position: 'fixed',
    borderRadius: '0px',
    zIndex: 999,
  },
  drawerDiv: {
    height: '100%',
  },
}))

export function Layout() {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(o => !o)
  return (
    <>
      <div className={classes.headerGroup}>
        <MainAppBar toggleOpen={toggleOpen} />
        <ResponsiveDrawer toggleOpen={toggleOpen} isOpen={isOpen}>
          <PbiTipsToolsLinks />
          <PbiTipsSocialLinks />
        </ResponsiveDrawer>
        <TabBar />
      </div>
      <Content />
      {/* <FooterBar /> */}
    </>
  )
}

function TabBar() {
  const classes = useStyles()
  return (
    <>
      <Box sx={{height: '48px', minHeight: '48px'}} />
      <Paper className={classes.tabs}>
        <Route
          path='/'
          render={({location}) => <TabNavigation location={location} />}
        />
      </Paper>
      <div style={{minHeight: '48px'}} />
    </>
  )
}
function Content() {
  return (
    <Switch>
      <Route exact path='/release-plan' component={ReleasePlan} />
      <Route path='/known-issues' component={KnownIssues} />
      <Route path='/ideas' component={Ideas} />
      <Route path='/bookmarks' component={Bookmarks} />
    </Switch>
  )
}
