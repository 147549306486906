import {Tab, Tabs} from '@mui/material'
import {Location} from 'history'
import {useEffect} from 'react'

import {useHistory} from 'react-router-dom'
import {sendGAPageView} from '../../utils/analyticsHelper'

export const pages = [
  {path: '/release-plan', title: 'Release Plan'},
  {path: '/known-issues', title: 'Fabric Issues'},
  {path: '/ideas', title: 'Ideas'},
  {path: '/bookmarks', title: 'Bookmarks'},
]

export function TabNavigation(props: TabNavigationProps) {
  const {location} = props

  useEffect(() => {
    if (location.pathname === '/') {
      history.push('/bookmarks')
    }
    sendGAPageView(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  let history = useHistory()

  return (
    <>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        {pages.map((page, index) => (
          <Tab
            key={page.path}
            label={page.title}
            value={page.path}
            onClick={() => {
              history.push(page.path)
              sendGAPageView(page.title)
            }}
          />
        ))}
      </Tabs>
    </>
  )
}

export type TabNavigationProps = {
  location: Location
}
