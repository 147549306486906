import {useEffect} from 'react'
import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'

import {PageContainer} from '../components'

const useStyles = makeStyles((theme: Theme) => ({
  adsContainer: {
    backgroundColor: '#fcfcfc',
    width: 'auto',
    height: 'auto',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  report: {
    width: '100%',
    height: '100%',

    zindex: -1,
  },
}))

export function Bookmarks() {
  const classes = useStyles()

  // REFRESH ADS WHEN THE PAGE LOADS
  useEffect(() => {}, [])

  return (
    <PageContainer>
      <iframe
        title='Bookmarks'
        src='https://raindrop.io/Power_BI_tips/power-bi-28654809/sort=-created'
        frameBorder='0'
        className={classes.report}
      ></iframe>
    </PageContainer>
  )
}
